#wrapper {
    width: 40px;
	position: relative;
	cursor:pointer;
	padding: 0 1em;
}
.circle {
	width:100%;
	height:100%;
    position:relative;
}
.line {
	position: absolute;
	height: 2px;
	width:100%;
	background:black;
	border-radius:5px;
	transition: all cubic-bezier(0.165, 0.84, 0.44, 1) 2s;
}
.top {
	top:40%;
}
.bottom {
	top:60%;
}
.icon.close .top {
	transform:rotate(405deg);
	top:50%;
}
.icon.close .bottom {
	transform:rotate(-405deg);
	top:50%;
}